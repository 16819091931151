import React, { PureComponent } from 'react';
import { store } from '../../App/store';
import Bowser from 'bowser';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const browser = Bowser.parse(window.navigator.userAgent);
//const browserString = JSON.stringify(browser);
let bName = browser.browser.name;
let bVersion = browser.browser.version;
if (bVersion.indexOf(".") > -1) {
  bVersion = Number(bVersion.split(".")[0] + "." + bVersion.split(".")[1]);
}
let bEngine = browser.engine.name;

let outdated = false;
if (bName === "Internet Explorer" || 
  bEngine === "EdgeHTML" ||
  (bName === "Safari" && bVersion < 13)) 
  {
    outdated = true;
  }

class TopbarAlert extends PureComponent {
  static contextType = store;

  constructor(props){
    super(props);
    this.state = {
      userBrowser: browser,
      serverError: false,
      dashboardError: false
    }
  }

  saveData = (key, value, store_key) => {
    if(typeof(value) === "object") {
      sessionStorage.setItem(key,JSON.stringify(value));
      cookies.set(key, JSON.stringify(value), {path: '/', maxAge: 3600, secure: true, sameSite: 'strict'});
    } else {
      sessionStorage.setItem(key, value);
      cookies.set(key, value, {path: '/', maxAge: 3600, secure: true, sameSite: 'strict'});
    }

    if(store_key){
      const { dispatch } = this.context;
      dispatch({type: store_key, value: value});
    }
  };

  componentDidMount = async () => {
    /*
    // Dashboard alert
    const location = window.location;
    var path = location.pathname.indexOf('/app/') > -1 ? location.pathname.split('/app/')[1] : location.pathname.substring(1);
    if(path.indexOf('web') > -1 || path.indexOf('email') > -1 || path.indexOf('survey') > -1 || path.indexOf('leads') > -1 || path.indexOf('home') > -1 || path.indexOf('support') > -1) {
      this.setState({dashboardError: true})
    }
    */
    //console.log(browser);
    this.saveData('userBrowser', browser, 'SET_USER_BROWSER');

  }

  render() {
    const { serverError, dashboardError } = this.state;

    if (outdated) {
      return (
        <div className="topbar-alert">
          <span>Your browser is not fully supported. Please use the latest version of Chrome, Firefox, Safari, or Microsoft Edge.</span>
        </div>
      )
    }

    if (serverError) {
      return (
        <div className="topbar-alert">
          <span>We are having some unexpected issues. <br /><br />The site is currently down, but we’re working hard to get it fixed. Please continue to check back on the progress with this issue.</span>
        </div>
      )
    }

    if (dashboardError) {
      return (
        <div className="topbar-alert grey-alert">
          <span>We are currently investigating an issue with the “Expand details” card functionality. Please check back soon!</span>
        </div>
      )
    }

    return null;
  }
}

export default TopbarAlert;
